import React from "react"
import Layout from '@components/layout/layout.jsx'
import DocumentHeadMeta from '@components/utility/SEO.jsx'
import { StaticImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import './styles.scss'
//Components
import Hero from '@components/hero/hero.jsx'
import SignupForm from '@components/form/Form.Signup.jsx'
// markup
const RegisterPage = ({ data }) => {
  const HeroImage = getImage(data.hero)
  return (
    <Layout addedClass="page--register">
      <DocumentHeadMeta title='Join the ALL IN&reg; Community | ALL IN&reg; for LN' description='Join the ALL IN&reg; community by signing up today to receive ongoing updates about lupus nephritis, community events, health and wellness tips, kidney-friendly recipes, and more.' canonicalURL='https://www.allinforlupusnephritis.com/register' />
      <Hero heroImage={HeroImage} imgAlt={"Image of person holding the ALL IN® Lupus Nephritis Awareness Kit"}>
        <h1>Join The ALL IN&reg; Community</h1>
        <p className="hero__subheading"><strong>When you register, you'll receive:</strong></p>
        <div className="checklist-row__list">
          <div className="checklist-row__item">
            <div className="icon"><StaticImage src="../../images/check-icon.png" className="" quality="100" placeholder="NONE" alt="" width={32} ></StaticImage></div>
            <div className="text">
              <p className="no-margin">Your Lupus Nephritis Awareness Kit in the mail which includes:</p>
              <ul className="hero-list">
                <li>An informative brochure</li>
                <li>Disease awareness bracelets</li>
                <li>A USB drive with digital materials to help you better understand and manage lupus nephritis</li>
                <li>Once registered, you will have immediate access to all the digital resources with a link provided in your confirmation email</li>
                <li><AnchorLink class="link--purple" to="/register#Explore-Kit">Explore the kit</AnchorLink></li>
              </ul>
            </div>
          </div>
          <div className="checklist-row__item">
            <div className="icon"><StaticImage src="../../images/check-icon.png" className="" quality="100" placeholder="NONE" alt="" width={32} ></StaticImage></div>
            <div className="text">
              <p>Digital newsletters with updates on community events, new information about lupus nephritis, and health and wellness tips, including kidney-friendly recipes</p>
            </div>
          </div>
        </div>
      </Hero>
      <SignupForm />
      <section id="Explore-Kit" className="bg-light-blue">
        <div className="container container--all-in-kit">
            <h2>Your Lupus Nephritis Awareness Kit will include:</h2>

            <h3 className="heading">Helpful guides about lupus nephritis</h3>
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/register/icon-Glossary.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <h3>Glossary of terms</h3>
                  <p>Learn the meaning behind many common lupus nephritis terms so that you can better understand your disease and speak confidently with others about it.</p>
                </div>
            </div>
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/register/icon-FAQ.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <h3>Frequently asked questions</h3>
                  <p>Help expand your knowledge of lupus nephritis and how to manage it by reviewing answers to some frequently asked questions.</p>
                </div>
            </div>
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/register/icon-Question.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <h3>Questions to ask your doctor</h3>
                  <p>Be better prepared for having conversations about lupus nephritis with your doctor. Consider printing or downloading this mobile- and smart device- friendly guide and bringing it with you to your healthcare appointments.</p>
                </div>
            </div>
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/register/icon-Chat.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <h3>How to talk with family and friends</h3>
                  <p>Discussing lupus nephritis with your family and friends may be difficult at times. Get tips to help you communicate your thoughts and feelings to those closest to you.</p>
                </div>
            </div>
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/register/icon-Caring.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <h3>A guide for care partners</h3>
                  <p>For those who care for someone with lupus nephritis, this resource offers tips to help care partners better support their loved one as well as themselves.</p>
                </div>
            </div>

            <h3 className="heading">Other resources</h3>
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/register/icon-SampleLetter.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <h3>Sample letter to your employer or school</h3>
                  <p>Consider referring to this sample letter when preparing a letter to notify your employer or school about your disease, including considerations for creating a healthy working or learning environment.</p>
                </div>
            </div>
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/register/icon-Bracelet.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <h3>Bracelets you can wear and share to show your community support</h3>
                  <p>Help raise awareness of lupus nephritis by wearing one of our #ALLINforLN bracelets and sharing the other one with a family member or friend.</p>
                </div>
            </div>
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/register/icon-USB.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <h3>A USB drive created for you</h3>
                  <p>On the USB drive, you'll find materials to help you better understand and manage lupus nephritis. Can't wait for your kit to arrive in the mail? Once registered, you will have immediate access to all the digital resources with a link provided in your confirmation email.</p>
                </div>
            </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="container">
          <h2>Register now to receive your kit</h2>
          <p className="less-margin">Fill out the form to get all of the resources listed above.</p>
          <AnchorLink to="/register#Sign-Up" className="cta--purple cta--body">Sign up</AnchorLink>
        </div>
      </section>
    </Layout>
  )
}

export default RegisterPage

export const pageQuery = graphql`
  query RegisterQuery {
    portraitSara: file(relativePath: {eq: "portrait-Sara.png"}) {
      childImageSharp {
        gatsbyImageData(height:240)
      }
    }
    hero: file(relativePath: {eq: "register/hero-register.jpg"}) {
        childImageSharp {
          gatsbyImageData(width:475,layout:FIXED)
        }
      }
  }
`
