import useForm from './useForm.js';
import React, { useState, useCallback, useEffect, useRef } from "react";
import validate from "../../utils/util-validator";
import ruleSet from './ruleSet.js';
import { StaticImage } from "gatsby-plugin-image"
import { navigate, Link } from "gatsby";
import { isEDMode, getNested } from '@src/helper';
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3';
// import Anchor from "@components/content/anchor.jsx"


// Style Imports
import "./Form.scss"
// Utility Imports
import { getVisitorId, getApiEndpoint } from '@src/helper';


const SignupForm = ({ googleReCaptchaProps }) => {
    const [submitting, setSubmitting] = useState(false);

    const getToken = useCallback(async () => {
        if (!googleReCaptchaProps.executeRecaptcha) {
            console.log('Recaptcha has not been loaded');
            return;
        }
        const _token = await googleReCaptchaProps.executeRecaptcha("SignupForm");
        return _token;
    }, [googleReCaptchaProps]);



    const success = useCallback((vals) => {
        fetch(getApiEndpoint() + 'allinforln/signup', {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(vals),
        })
        .then(async (resp) => {
            var r = await resp.json();
            if (r.success) {
                gtmFormSuccess()
                gtmFormFieldCompletionSubmitted()
                navigate('/registration-confirmed',
                    {
                        state: {
                            success: true
                        }
                    });
            } else {
                navigate('/500');
            }
        })
        .catch(() => {
            navigate('/500');
        })
    }, [])



    //GTM
    const gtmFormSuccess = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Form Submission',
            'Event_Category': 'Form Submissions',
            'Event_Action': 'Signup',
            'Event_Label': 'Complete'
        });
    }
    const gtmFormFieldError = (errors) => {
        if (errors) {
            for (var error in errors) {
                const selector = ".error--" + error;
                if (document.querySelector(selector)) {
                    const errorText = document.querySelector(selector).innerText;
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'Form Errors',
                        'Event_Category': 'Validation Errors',
                        'Event_Action': 'Signup',
                        'Event_Label': error + '/' + errorText
                    });
                }
            }
            setSubmitting(false);
        }
    }
    const gtmFieldCompletion = () => {
        document.querySelectorAll('.form--signup input, .form--signup select').forEach(field => {
            if (!field.getAttribute('data-has-ga-listener')) {
                field.setAttribute('data-has-ga-listener', 'true')
                field.addEventListener('change', fieldCompletionEvent);
            }
        });
    }

    const fieldCompletionEvent = function (e) {
        const field = e.target;
        let fieldInfo;
        switch (field.type) {
            case ('radio'):
                fieldInfo = (field.getAttribute('gaVal')) ? (field.name + '_' + field.getAttribute('gaVal')) : 'No gaVal found';
                break;
            case ('checkbox'):
                fieldInfo = field.name + '_' + field.checked;
                break;
            case ('select-one'):
                fieldInfo = field.name + '_' + field.value;
                break;
            default:
                fieldInfo = field.name
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Field Completion',
            'Event_Category': 'Form Field Completion',
            'Event_Action': 'Signup',
            'Event_Label': fieldInfo
        });
    }

    const gtmFormFieldCompletionSubmitted = () => {
        document.querySelectorAll('.form--signup input, .form--signup select').forEach(field => {
            let fieldInfo;

            if ((field.type === 'radio' || field.type === 'checkbox') && !field.checked) return;
            if (field.value.length === 0) return;

            switch (field.type) {
                case ('radio'):
                    fieldInfo = (field.getAttribute('gaVal')) ? (field.name + '_' + field.getAttribute('gaVal')) : 'No gaVal found';
                    break;
                case ('checkbox'):
                    fieldInfo = field.name + '_' + field.checked;
                    break;
                case ('select-one'):
                    fieldInfo = field.name + '_' + field.value;
                    break;
                default:
                    fieldInfo = field.name
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'Form Field Completion_Form Submitted',
                'Event_Category': 'Form Field Completion_Form Submitted',
                'Event_Action': 'Signup',
                'Event_Label': fieldInfo
            });
        })
    }

    useEffect(() => {
        gtmFieldCompletion();
    })

    const [{
        values,
        errors,
        handleChange,
        handleSubmit,
    }] = useForm(success, validate, ruleSet.signup.rules, { source: 'aurinia-allinforln-redesign-signup', visitorId: getVisitorId(), optin: true }, () => { }, gtmFormFieldError);
    return (

    
        <section id='Sign-Up' className='bg-light-purple'>
        {/* <ReCaptchaComponent /> */}
            <div className="container container--narrow">
                <h2 className='text-left'>Register today</h2>
                <p className='text-left text--black'><strong>All fields are required</strong></p>
                <form className="form--signup" onSubmit={(e)=>{
                    e.preventDefault(); 
                    setSubmitting(true);
                    if (isEDMode) {
                        handleSubmit(e, {captchaResponse: 'emode'})
                    } else {
                        getToken().then((token)=>{handleSubmit(e, {captchaResponse: token})}); 
                    }
                    }}>
                    <div className="container--split">
                        <div className='split-block--50'>
                            <div className="single-col-input">
                                <label>
                                    First Name*
                                    <input aria-required="true" type="text" name="first_name" id="first_name" value={values.first_name || ''} onChange={handleChange} aria-invalid={errors?.first_name ? true : false} aria-labelledby={errors?.first_name ? "errorFname" : null} placeholder="First name" maxLength={'50'} />
                                </label>
                                {errors?.first_name && (
                                    <p id="errorFname" className="error error--first_name">Please enter your first name</p>
                                )}
                                <label>
                                    Last Name*
                                    <input aria-required="true" type="text" name="last_name" id="last_name" value={values.last_name || ''} onChange={handleChange} aria-invalid={errors?.last_name ? true : false} aria-labelledby={errors?.last_name ? "errorLname" : null} placeholder="Last name" maxLength={'50'} />
                                </label>
                                {errors?.last_name && (
                                    <p id="errorLname" className="error error--last_name">Please enter your last name</p>
                                )}
                                <label>
                                    Email Address*
                                    <input aria-required="true" type="text" name="email" id="email" placeholder="e.g. jsmith@email.com" onChange={handleChange} aria-invalid={errors?.email ? true : false} aria-labelledby={errors?.email ? "errorEmail" : null} maxLength={'50'} />
                                </label>
                                {errors?.email && (
                                    <p id="errorEmail" className="error error--email">Please input a valid email address</p>
                                )}
                            </div>
                            <div className="single-col-input address-input">
                                <label>
                                    Mailing Address*
                                    <input aria-required="true" type="text" name="address_street" id="address_street" value={values.address_street || ''} placeholder="Street address 1" onChange={handleChange} aria-invalid={errors?.address_street ? true : false} aria-labelledby={errors?.address_street ? "errorAddressStreet" : null} maxLength={'50'} />
                                </label>
                                {errors?.address_street && (
                                    <p id="errorAddressStreet" className="error error--address_street">Please input a valid address</p>
                                )}

                                <label>
                                    City*
                                    <input aria-required="true" type="text" name="address_city" id="address_city" value={values.address_city || ''} onChange={handleChange} placeholder="City" aria-invalid={errors?.address_city ? true : false} aria-labelledby={errors?.address_city ? "errorAddressCity" : null} maxLength={'50'} />
                                </label>
                                {errors?.address_city && (
                                    <p id="errorAddressCity" className="error error--address_city">Please input a city</p>
                                )}

                                <label>
                                    State*
                                    <select aria-required="true" name="address_state" id="address_state" value={values.address_state || ''} onChange={handleChange} aria-invalid={errors?.address_state ? true : false} aria-labelledby={errors?.address_state ? "errorAddressState" : null}>
                                        <option value="">State</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </select>
                                </label>
                                {errors?.address_state && (
                                    <p id="errorAddressState" className="error error--address_state">Please input a state</p>
                                )}
                                <label>
                                    ZIP Code*
                                    <input aria-required="true" type="text" name="address_zipcode" id="address_zipcode" value={values.address_zipcode || ''} placeholder="ZIP code" onChange={handleChange} aria-invalid={errors?.address_zipcode ? true : false} aria-labelledby={errors?.address_zipcode ? "errorZipCode" : null} maxLength={'5'} />
                                </label>
                                {errors?.address_zipcode && (
                                    <p id="errorZipCode" className="error error--address_zipcode">Please input a valid ZIP code (i.e.&nbsp;12345)</p>
                                )}
                                <label>
                                    I am a...
                                    <select aria-required="true" name="designation" id="designation" value={values.designation || ''} onChange={handleChange} aria-invalid={errors?.designation ? true : false} aria-labelledby={errors?.designation ? "errorDesignation" : null}>
                                        <option value="">Select from drop down list</option>
                                        <option value="Person living with lupus">Person living with lupus</option>
                                        <option value="Person living with lupus and showing signs of lupus nephritis">Person living with lupus and showing signs of lupus nephritis</option>
                                        <option value="Person living with lupus and lupus nephritis">Person living with lupus and lupus nephritis</option>
                                        <option value="The care partner of someone living with lupus">The care partner of someone living with lupus </option>
                                        <option value="The care partner of someone living with lupus and showing signs of lupus nephritis">The care partner of someone living with lupus and showing signs of lupus nephritis</option>
                                        <option value="The care partner of someone living with lupus and lupus nephritis">The care partner of someone living with lupus and lupus nephritis</option>
                                        <option value="Healthcare Professionals">Healthcare professional</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </label>
                                {errors?.designation && (
                                    <p id="errorDesignation" className="error error--designation">Please select the statement that best describes you or the person you are representing</p>
                                )}
                                { (values.designation == "Person living with lupus and lupus nephritis") ?
                                [
                                    <div className='text-left'>
                                        <label>
                                            Year diagnosed with lupus nephritis
                                            <input aria-required="true" type="text" name="year_diagnosed" id="year_diagnosed" value={values.year_diagnosed || ''} placeholder="Enter year" onChange={handleChange} aria-invalid={errors?.year_diagnosed ? true : false} aria-labelledby={errors?.year_diagnosed ? "errorYear" : null} maxLength={'4'} />
                                        </label>
                                        {errors?.year_diagnosed && (
                                            <p id="errorYear" className="error error--year_diagnosed">Please enter a correct year</p>
                                        )}
                                    </div>
                                ] : ''}
                            </div>
                        </div>
                        <div className="split-block--50">
                            <StaticImage src="../../images/register/all-in-kit.png" placeholder="NONE" quality={100} alt="Image of ALL IN® Lupus Nephritis Awareness Kit" width={600}/>
                        </div>
                    </div>
                    <div className="agreement"><p>By clicking submit below, you agree that the information you voluntarily provide may be used by Aurinia Pharma U.S., Inc. or other companies acting on its behalf to provide you with information about educational materials, resources, market research, approved treatment options, and programs/services about lupus nephritis. Aurinia will handle your personal information in accordance with Aurinia's <a href="https://www.auriniapharma.com/privacy-statement" target="_blank">Privacy Statement</a>. For additional information, please read our Privacy Statement. You may <Link to="/unsubscribe">unsubscribe</Link> from our list at any time.</p></div>
                    <div className="checkbox-wrapper">
                        <input aria-required="true" name="eighteen_years" id="eighteen_years" type="checkbox" checked={values.eighteen_years} onChange={handleChange} aria-invalid={errors?.eighteen_years ? true : false} />
                        <label htmlFor="eighteen_years">By checking this box I certify that I am 18&nbsp;years of age or older and I reside in the United States.</label>
                    </div>
                    {errors?.eighteen_years && (
                        <p className="error error--eighteen_years">Please confirm</p>
                    )}
                    <button type="submit" className={"cta--purple cta--body cta--submit" + (submitting ? " button--submitting" : "")}>Sign Up</button>
                </form>
            </div>
        </section>
    )
}

const SignupFormWithCaptcha = withGoogleReCaptcha(SignupForm);
export default function SignupFormWrapper () {
    return(
        <GoogleReCaptchaProvider reCaptchaKey="6LcuTWwhAAAAABVfYC6XlGMwTPOwpyiRXKdc5X4O" >
            <SignupFormWithCaptcha/>
        </GoogleReCaptchaProvider>
    )
}
